export const fontStyles = [
  "Impact",
  "Arial",
  "Comic Sans MS",
  "Helvetica",
  "Times New Roman",
  "Courier New",
  "Verdana",
  "Georgia",
  "Bookman",
  "Trebuchet MS",
  "Cooper",
];
