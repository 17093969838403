import React from 'react'
import MemeGenerator from "../components/MemeGenerator";

export default function Meme(){
    const [allMeme,setAllMeme]=React.useState([])
    React.useEffect(() => {
        async function fetchData(){
            const res=await fetch('https://api.imgflip.com/get_memes')
            const data=await res.json()
            setAllMeme(data.data.memes)
            console.log(data.data.memes)
            if(data.data.memes){
                randomMeme()
            }
        }
        fetchData()
    }, [])

    const [meme,setMeme]=React.useState({
        topText:"",
        bottomText:"",
        imgUrl:'https://i.imgflip.com/64sz4u.png',
        imgAlt:'No meme ?'
    })

    function randomMeme(){
        const randomNum=Math.floor(Math.random() * allMeme.length)
        const currMeme=allMeme[randomNum]
        const memeimg=currMeme["url"]
        const memealt=currMeme["name"]
        setMeme(prevState=>({
            ...prevState,
            imgUrl:memeimg,
            imgAlt:memealt,
        }))
    }

    function handleImageUpload(event: React.ChangeEvent<HTMLInputElement>) {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const imageUrl = reader.result as string;
                setMeme(prevState => ({
                    ...prevState,
                    imgUrl: imageUrl,
                    imgAlt: 'Custom Meme'
                }));
            };
            reader.readAsDataURL(file);
        }
    }

    return (
        <main>
            <div className='flex flex-col sm:justify-around items-center mt-3 justify-center'>
                <p className="p-2"> ミ★ Reprend aléatoirement dans le top 100 des memes les plus populaires du moment ★彡 </p>
                <button className='cursor-pointer bg-gradient-to-r from-slate-900 to-slate-700 md:w-[477px] w-96 md:h-[40px] h-16 rounded-md text-white font-Karla' onClick={randomMeme}>↓ Générer un nouveau Meme ↓</button>
                <div className="h-4"></div> {/* Add an empty div for spacing */}
                <label htmlFor="image-upload" className='cursor-pointer text-center bg-gradient-to-r from-slate-900 to-slate-700 md:w-[477px] w-96 md:h-[40px] h-16 rounded-md text-white font-Karla'>
                 Ajouter une image personnalisée
                    <input id="image-upload" className="hidden" type="file" accept="image/*" onChange={handleImageUpload} /> {/* Add an input field for uploading images */}
                </label>
            </div>


            <MemeGenerator
                imageUrl={meme.imgUrl}
                box_count={2}
                imageName={meme.imgAlt}
            />
        </main>
    )
}