export default function Navbar() {

  return (
    <div className="w-full p-3 gap-5 bg-gradient-to-r from-slate-900 to-slate-700">
      <h1 className="text-4xl font-semibold text-center text-white">
        <p>𝐆é𝐧é𝐫𝐚𝐭𝐞𝐮𝐫 𝐝𝐞 𝐌𝐞𝐦𝐞</p>
      </h1>
    </div>
  );
}
